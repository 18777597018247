<template>
  <div class="warp">
    <p>企业固废码使用说明</p>
    <p>1. 可长按二维码另外为图片，可打印张贴。</p>
    <p>2. 该企业固废码扫码后，可查看该企业的固废收运情况及去向，最近收运时间等。</p>
    <div class="ewm">
      <vue-qr :text="qrCodeUrl"
              :margin="0"
              colorDark="#333"
              colorLight="#fff"
              :logoScale="0.3"
              :size="200"></vue-qr>
    </div>
    <div class="btn">
      <van-button round block type="default" @click="gobackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { companyInfo } from '@/api/notice.js'
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  data () {
    return {
      qrCodeUrl: ''
    }
  },
  created() {
    this.companyInfo()
  },
  methods: {
    companyInfo() {
      companyInfo().then(res => {
        if (res.data.code === 200) {
          const usccCs = res.data.data.info.uscc
          this.qrCodeUrl = 'https://gy-wx.nbhhl.com/Protectiondetails?uscc=' + usccCs
        }
      })
    },
    gobackClick() { // 返回
      this.$router.push({path: 'Profileuser'})
    },
  }
}

</script>
<style lang="scss" scoped>
.warp {
  padding: 15px;
  p {
    line-height: 25px;
    font-size: 14px;
  }
  .ewm {
    text-align: center;
    margin-top: 30px;
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
</style>
